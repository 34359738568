<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <!-- <li class="menu-title" *ngIf="item.menuLevel.name">
          {{ item.menuLevel.display_name | translate }}
        </li> -->
        <li *ngIf="item.menuLevel.active">
          <a
            *ngIf="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.menuLevel.display_name | translate }}</span>
          </a>

          <a
            [routerLink]="item.menuLevel.route_name"
            *ngIf="!hasItems(item)"
            class="side-nav-link-ref"
            routerLinkActive="active"
          >
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.submenus">
              <ng-container *ngIf="subitem.submenuLevel.active">
                <a
                  [routerLink]="subitem.submenuLevel.route_name"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                  (click)="setPermissions(subitem)"
                >
                  {{ subitem.submenuLevel.display_name | translate }}
                </a>
                <a
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.submenuLevel.display_name | translate }}
                </a>
              </ng-container>
              <!-- <ul
                *ngIf="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a
                    [attr.data-parent]="subSubitem.parentId"
                    [routerLink]="subSubitem.link"
                    routerLinkActive="active"
                    class="side-nav-link-ref"
                  >
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul> -->
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
