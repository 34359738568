import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Staffs, iStaffList, StaffList, SearchStaff } from "../models/auth.models";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { iCountries } from "../models/country.model";

interface iDesignation {
  data: {
    designations: Array<{ _id: string; name: string }>;
  };
}



@Injectable({ providedIn: "root" })
export class UserProfileService {
  profileDetails$ = new BehaviorSubject<Staffs>(null);

  constructor(private http: HttpClient) {}
  getUser() {
    return localStorage.getItem("acct");
  }

  getProfile(id: string) {
    return this.http.get<{ data: Staffs }>(
      `${environment.API_URL}/staff/view-staff/${id}`
    );
  }
  getAllStaff() {
    return this.http.get<iStaffList>(`${environment.API_URL}/staff`);
  }


  getStaffbyId(id: string) {
    return this.http.get<{ data: StaffList }>(
      `${environment.API_URL}/staff/view-staff/${id}`
    );
  }

  addStaff(
    name: string,
    email: string,
    password: string,
    contact: string,
    addedBy: string
  ) {
    return this.http.post(`${environment.API_URL}/staff/add-staff`, {
      name,
      email,
      password,
      contact,
      addedBy,
    });
  }

  updateStaff(id: string, updatedProfile: Object) {
    return this.http.patch(
      `${environment.API_URL}/staff/update-staff/${id}`,
      updatedProfile
    );
  }



  searchStaff(searchFields: { [key: string]: string | string[] }) {
    const processedSearchFields = { ...searchFields };

    return this.http.post<iStaffList>(
      `${environment.API_URL}/staff/search-staff`, 
      processedSearchFields
    );
  }

  getDesignation() {
    return this.http.get<iDesignation>(
      `${environment.API_URL}/staff/designations`
    );
  }

 



  // register(user: User) {
  //     return this.http.post(`/users/register`, user);
  // }
}
