import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// import { getFirebaseBackend } from '../../authUtils';

import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  userToken: string;

  constructor(private _http: HttpClient) {
    const user = this.getToken();
    if (user) this.isLoggedIn$.next(true);
  }

  setToken(user: string, token: string) {
    localStorage.setItem("adminToken", JSON.stringify(token));
    localStorage.setItem("acct", JSON.stringify(user));
  }

  getToken() {
    return localStorage.getItem("adminToken");
  }

 

  removeToken() {
    localStorage.removeItem("adminToken");
  }

  /**
   * Returns the current user
   */
  // public currentUser(): User {
  //     return getFirebaseBackend().getAuthenticatedUser();
  // }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return this._http
      .post<{ user: string; token: string, status: string }>(
        `${environment.API_URL}/staff/login`,
        { email, password }
      )
      .pipe(
        tap((res) => {
          this.isLoggedIn$.next(true);
          this.setToken(res.user, res.token);
        })
      );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  // register(email: string, password: string) {
  //     // return getFirebaseBackend().registerUser(email, password).then((response: any) => {
  //     //     const user = response;
  //     //     return user;
  //     // });
  //     return this._http.post<{message:String, token: String}>(`${environment.API_URL}/signup`, { email, password })
  // }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(password: string, confirmPassword: string, token: string) {
    return this._http
      .patch<{ message: string; token: string }>(
        `${environment.API_URL}/staff/reset-password/${token}`,
        { password, confirmPassword }
      )
      .pipe(
        tap((res) => {
          this.isLoggedIn$.next(true);
          this.setToken(res.message, res.token);
        })
      );
  }

  resetpassword(data: any) {
    return this._http.patch<{ message: string; token: string }>(
      `${environment.API_URL}/staff/reset-password`,
      { data }
    );
  }

  forgotPassward(email: string) {
    return this._http.post<{ message: string }>(
      `${environment.API_URL}/staff/forgot-password`,
      { email }
    );
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    // getFirebaseBackend().logout();
    this.removeToken();
    this.isLoggedIn$.next(false);
  }
}
