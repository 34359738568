import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { iMenuLevel, iMenuList, MenuLevel, SubMenuLevel } from '../models/menu.model';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private _http: HttpClient) { }
  private permissionSubject = new BehaviorSubject<any>(null);


  getAllPermissions() {
    return this._http.get<{data: iMenuLevel}>(`${environment.API_URL}/staff/dashboard`).pipe(
      map(response => response.data.menus)
    );
  }

  setPermissions(permissions: MenuLevel[]) {
    this.permissionSubject.next(permissions);
    return this.permissionSubject.asObservable();
  }


  getPermissions() {
    return this.permissionSubject.asObservable().pipe(shareReplay(1));
  }
}
