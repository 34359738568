import { Component , OnInit} from '@angular/core';
import { PermissionsService } from './core/services/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  constructor(private _permissionService: PermissionsService) { }

  ngOnInit() {
   
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
